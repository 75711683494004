@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.link {
  text-decoration: none;
  color: white;
}

.builder-container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 20% 65% 15%;
}

.builder-sidebar {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f7f9fa;
}

.builder-item {
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}

.builder-main {
  border-right: 2px solid #c3cfd9;
  border-left: 2px solid #c3cfd9;
  background-color: white;
}

.builder-title-section {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 120px;
}

.builder-component-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.builder-helper {
  background-color: #f7f9fa;
}

.builder-helper-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.builder-helper-footer-items {
  display: flex;
}

.builder-helper-footer-items Button {
}
